

.header-new {
    display: flex;
}

section {
    width: 100%;
}

.left-col {
    flex-basis: 10%;
}

.right-col {
    flex-basis: 90%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}


@media (min-width: 280px) and (max-width: 1366px) {
    nav {
        display: none;
    }

    .header-new {
        justify-content: space-between;
    }

    .right-col {
        justify-content: flex-end;
    }
}

@media (min-width: 280px) and (max-width: 1280px) {
    nav ul li a {
        font-size: 14px;
    }

    ul {
        gap: 2em;
    }
}

.right-col nav {
    /* width: 75%; */
}

.right-col .form-input {
    width: 10%;
}

.right-col nav ul {
    display: flex;
    align-items: center;
    gap: 4em;
    flex: 2;
}

.right-col nav ul li {
    list-style-type: none;
}

.right-col nav ul a {
    text-decoration: none;
    color: #212B36;
    padding-bottom: 0.5em;
    font-weight: 600;
}


.no-border {
    border: none;
}

.active {
    border-bottom: 1px solid #5E8A75;
}

.input-border {
    border: 1px solid #919EAB;
}

.header-right {
    display: flex;
    gap: 1;
    align-items: center;
    margin-top: 2;
}

@media (min-width: 280px) and (max-width: 460px) {
    .header-right {
        gap: 5px;
        flex-direction: column-reverse;

    }
    .notifcationContainer {
        margin-top: 10px;
    }
}

.ag-paging-row-summary-panel {
    display: flex;
    gap: 2px
}

@media (max-width: 600px) {
    .ag-paging-panel {
        display: flex;
        flex-direction: column;
        height: 90px !important;
        justify-content: center;
        align-items: center;
        gap: 4px !important;
        padding: 4px 0px;
    }
    .MuiIconButton-root {
        padding: 0.2em !important;
    }
    .MuiSvgIcon-root {
        width: 15px !important;
        height: 15px !important;
    }
    .left-col img {
        width: 100px;
    }
}

.dropdown-header:hover {
    background: var(--surfaceLighter);
    color: var(--black);
}

.inactive-npi {
    background-color: var(--danger);
    color: var(--white);
    padding: 8px;
    width: 95%;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.inactive-npi-mobile {
width: 100%;
margin-top: 8px;
}
